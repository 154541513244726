<template>
  <div class="m-grid m-grid-page p-maintain-list">
    <section class="m-grid-search">
      <znl-input
          v-model="searchData.TaskDateTime"
          border
          class="m-margin--right-10"
          clearable
          form-type="datepicker"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期"
          width="160px"></znl-input>
      <znl-input
          v-model="searchData.UserIds"
          :select-options="systemUser"
          border
          class="m-margin--right-10"
          clearable
          form-type="select"
          :multiple="true"
          placeholder="所属人员"
          width="150"
      >
      </znl-input>
      <el-button class="m-grid-btn" type="primary" @click="onCountSearch()">查询</el-button>

    </section>
    <section class="p-maintain-task">
      <div class="p-maintain-task_box">
        <p class="p-maintain-task_title">我的任务</p>
        <ul class="p-maintain-task_box--ul">
          <li class="p-maintain-task_box--li">
            <p class="title"><span>本周</span></p>
            <div class="box">
              <p><span>任务分数：{{ MytaskInfo.MyTaskForWeekScore }}分</span></p>
              <p><span>任务（已完成/全部）：{{ MytaskInfo.MyTaskForweekCountCompary }}</span></p>
            </div>
          </li>

          <li class="p-maintain-task_box--li">
            <p class="title"><span>本月</span></p>
            <div class="box">
              <p><span>任务分数： {{ MytaskInfo.MyTaskForMonthScore }}分</span></p>
              <p><span>任务（已完成/全部）：{{ MytaskInfo.MyTaskForMonthCountCompary }}</span></p>
            </div>
          </li>

          <li class="p-maintain-task_box--li">
            <p class="title"><span>本季度</span></p>
            <div class="box">
              <p><span>任务分数： {{ MytaskInfo.MyTaskForSeasonScore }}分</span></p>
              <p><span>任务（已完成/全部）：{{ MytaskInfo.MyTaskForSeasonCountCompary }}</span></p>
            </div>
          </li>
        </ul>
      </div>
      <div class="p-maintain-task_box">
        <p class="p-maintain-task_title"> 我的客户 <span>(当前客户总数{{ myCustomer.TotalCustomer }}个)</span></p>
        <ul class="p-maintain-task_box--ul">
          <li class="p-maintain-task_box--li">
            <p class="title"><span>本周</span></p>
            <div class="box">
              <p><span>新增客户数：{{ myCustomer.MyTaskForWeekAddCustomer }}个</span></p>
              <p><span>流失客户数：{{ myCustomer.MyTaskForWeekLostCustomer }}个</span></p>
            </div>

          </li>
          <li class="p-maintain-task_box--li">
            <p class="title"><span>本月</span></p>
            <div class="box">
              <p><span>新增客户数：{{ myCustomer.MyTaskForMonthAddCustomer }}个</span></p>
              <p><span>流失客户数：{{ myCustomer.MyTaskForMonthLostCustomer }}个</span></p>
            </div>

          </li>
          <li class="p-maintain-task_box--li">
            <p class="title"><span>本季度</span></p>
            <div class="box">
              <p><span>新增客户数：{{ myCustomer.MyTaskForseaonAddCustomer }}个</span></p>
              <p><span>流失客户数：{{ myCustomer.MyTaskForseaonLostCustomer }}个</span></p>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="m-grid-search">
      <znl-input
        v-model="listQuery.TaskType"
        :select-options="taskTypeList"
        border
        class="m-margin--right-10"
        clearable
        form-type="select"
        placeholder="搜索任务类型"
        type="text"
        width="150px">
      </znl-input>
      <znl-input
        v-model="listQuery.TaskDateTime"
        border
        class="m-margin--right-10"
        clearable
        form-type="datepicker"
        value-format="yyyy-MM-dd HH:mm:ss"
        placeholder="选择日期"
        width="160px"></znl-input>
      <znl-input
        v-model="listQuery.TaskComplity"
        :select-options="ComplityTypeList"
        border
        class="m-margin--right-10"
        clearable
        form-type="select"
        placeholder="任务状态"
        type="text"
        width="150px">
      </znl-input>
      <znl-input
          v-model="listQuery.UserIds"
          :select-options="systemUser"
          border
          class="m-margin--right-10"
          clearable
          form-type="select"
          :multiple="true"
          placeholder="所属人员"
          width="150"
      >
      </znl-input>
      <el-button class="m-grid-btn" type="primary" @click="onSearch()">查询</el-button>

    </section>

    <section class="m-grid-list">
      <grid
        ref="list"
        :columns="columns"
        :datas="TaskList"
        headerHeight="35"
        @onColumnMoved="onColumnMoved"
        type="base">
      </grid>
    </section>

  </div>
</template>
<script>
import {geTaskTypes} from "@/api/config";
import {columns} from "./Modules/MaintainView/config";
import {GetMyTask, GetCountInfo, pullTask} from "@/api/Customer";
import { getSystemAuthUsers } from "@/api/common";

import { userColumnMixin } from "@/components/Mixins/UserColumnMixin"
const TableName = "myTask-list"

export default {
  name: "clientMaintain",
  mixins: [userColumnMixin],
  components: {},
  data() {
    return {
      columns: columns,
      itemSource: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      taskTypeList: [],
      ComplityTypeList: [
        {
          key: '',
          value: "全部",
        },
        {
          key: true,
          value: "是",
        }, {
          key: false,
          value: "否",
        }

      ],
      MytaskInfo: {
        //我的任务得分
        MyTaskForWeekScore: 0,
        MyTaskForweekCountCompary: "",
        MyTaskForMonthScore: 0,
        MyTaskForMonthCountCompary: "",
        MyTaskForSeasonScore: 0,
        MyTaskForSeasonCountCompary: "",
      },
      //我的客户得分
      myCustomer: {
        MyTaskForMonthAddCustomer: 0,
        MyTaskForMonthLostCustomer: 0,
        MyTaskForWeekAddCustomer: 0,
        MyTaskForWeekLostCustomer: 0,
        MyTaskForseaonAddCustomer: 0,
        MyTaskForseaonLostCustomer: 0,
        TotalCustomer: 0
      },
      //我的任务列表
      TaskList: [],
      MyConpanyInfo: {},
      value1: "",
      systemUser: [],
      searchData: {
        TaskDateTime: '',
        UserIds: []
      },
      listQuery: {
        TaskType: "",
        TaskComplity: '',
        TaskDateTime: '',
        UserIds: []
      }
    };
  },
  methods: {
    async getUsers() {
      const ret = await getSystemAuthUsers({ })
      let systemUser = []
      if (ret) {
        systemUser = ret.map((item) => {
          return {
            key: item.UserID,
            value: item.UserName,
          };
        });
      }
      this.systemUser = systemUser
    },
    async onSearch() {
      await this.getMyInfo();
    },
    async onCountSearch() {
      await this.getCountInfo();
    },
    async getMyInfo() {
      const date = await GetMyTask(this.listQuery);
      //我的列表
      this.TaskList = date.ListTask;
      //列表
      this.TaskList.forEach((item, index) => {
        item.rowIndex = index + 1;
      });
    },
    async getCountInfo() {
      const date = await GetCountInfo(this.searchData);
      //我的任务得分
      this.MytaskInfo = date.MyTask;
      // 我的客户得分
      this.myCustomer = date.Customer;
    },
    async taskTypes() {
      let data = await geTaskTypes();
      this.taskTypeList = this.setOption(data)
    },
    setOption(data) {
      return data.map((item) => {
        return {
          key: item.ItemValue,
          value: item.ItemName
        }
      });
    },
    async onColumnMoved(col) {
      if (col.column) {
        const { field, ColumnCode } = col.column.colDef
        const columns = this.$refs.list.$refs.table.columnDefs
        const localColumns = columns.map(a => { return { ...a, ColumnCode: a.field, ColumnName: a.headerName } })
        const newColumns = [...localColumns]
        const index = newColumns.findIndex(a => a.ColumnCode === (field || ColumnCode))
        if (index >= 0) {
          const _col = newColumns[index]
          newColumns.splice(index, 1)
          newColumns.splice(col.toIndex,0, _col)
          await this.onUserColumnMoved(newColumns, TableName)
        }
      }
    },
    // 表格组件处理
    // 初始化列表

    setButtons(params) {

      let data = params.data;
      let buttons = [];
      let self = this;

      if (data.Tasktype === 5 && !data.IsCompleted) {
        const otherBtn = {
          text: "检查任务",
          click(params) {
            self.pullTask(params.data);
          },
        };
        buttons.push(otherBtn);
      }
      else if(data.Tasktype != 5 && data.IsCompleted===false)
      {
        const otherBtn = {
          text: "去完成",
          click() {
            self.GoTask();
          },
        };
         buttons.push(otherBtn);

      }
      return buttons;
    },
    // 初始化表格
    async initColumns() {
      const self = this;
      const OriginColumn = columns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      const newColumns = await this.sort(columns, TableName)
      this.$refs.list.changeColumns(newColumns);
    },
    async pullTask(data) {
      const { TSID } = data;

      const ret = await pullTask({ TSID });
      if (!ret) {
        this.$message.error("检查失败");
        return
      }
      this.$message({
        type: "success",
        message: "检查成功!",
      });
       this.onSearch();
    },
    async GoTask() {
      this.$router.push('/clientManage/listView');
    },
    onOpenDelete(data) {
      if (data.IsPreset) {
        this.$message.warning("系统预设，不允许删除！");
        return;
      }
      const params = {
        Id: Number(data.SeaID),
      };
      this.$confirm("此操作将永久删除该公海配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteCommonConfig(params);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          await this.onSearch();
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "已取消删除",
          });
        });
    },
  },
  created() {
    this.taskTypes();
    this.getMyInfo();
    this.getCountInfo();
  },
  async mounted() {
    await this.initColumns()
    await this.getUsers()
  },
};
</script>

<style lang="scss">

.p-maintain-list {
  .p-maintain-task {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;

    .p-maintain-task_box {
      flex: .48;

      .p-maintain-task_title {
        font-size: 16px;
        color: #0a76a4;
        margin-bottom: 5px;

        span {
          font-size: 12px;
          font-weight: bold;
        }

      }

      .p-maintain-task_box--ul {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }

      .p-maintain-task_box--li {
        flex: .3;
        border: 1px solid #cccccc;
        min-width: 190px;
        margin-right: 5px;
        .title{
          background: #5a85eb;
          padding: 3px;
          color: #ffffff;
          box-sizing: border-box;
        }
        .box {
          padding: 5px;
          p {
            line-height: 24px;
          }
        }

      }
    }
  }


}
</style>
