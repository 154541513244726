const columns = [
    {
      field: 'rowIndex',
      headerName: '序号',
      width: 70,
      cellClass: 't-c'
    },
    {
      field: 'SetTimeType',
      headerName: '周期',
      width: 70,
      cellClass: 't-c',
      valueFormatter: ({ data: { SetTimeType } }) => {
        // 分配时间段.  周: week, 月：month， 季度: season
        let ret = ''
        switch (SetTimeType){
          case 'week':
            ret = '周'
            break;
          case 'month':
            ret = '月'
            break;
          case 'season':
            ret = '季度'
            break;
        }
        return ret;
      }
    },
    {
      field: 'TasKName',
      headerName: '任务名称',
      editable: false,
      width: 90,
      cellClass: 't-c'
    },{
			field: 'Tasktype',
			headerName: '任务类型',
			editable: false,
			width: 80,
			cellClass: 't-c',
			valueFormatter({ value }) {
				let ret = ''
				switch (value) {
					case 1:
						ret = '新增'
						break;
					case 2:
						ret = '修改'
						break;
					case 3:
						ret = '跟进'
						break;
					case 4:
						ret = '购买'
						break;
					case 5:
						ret = '其他'
						break;
				}
				return  ret
			}
		},
    {
      field: 'TaskDec',
      headerName: '任务说明',
      editable: false,
      cellClass: 't-c'
    },
    {
      field: 'DTaskscore',
      headerName: '单个分值',
      editable: false,
      width: 80,
      cellClass: 't-r'
    },
		{
			field: 'TaskQty',
			headerName: '任务数量',
			editable: false,
			width: 80,
			cellClass: 't-r'
		},
		{
			field: 'CompletedQty',
			headerName: '已完成任务数量',
			editable: false,
			width: 80,
			cellClass: 't-r'
		},
    {
      field: 'TargetScore',
      headerName: '任务总分值',
      editable: false,
      width: 100,
      cellClass: 't-r'
    },
    {
      field: 'CompletedQuota',
      headerName: '已完成金额',
      editable: false,
      width: 100,
      cellClass: 't-r'
    },
    {
      field: 'NoCompletedQuota',
      headerName: '未完成金额',
      editable: false,
      width: 100,
      cellClass: 't-r'
    },
    {
      field: 'TaskStarttime',
      headerName: '任务时间',
      editable: false,
      width: 120,
      cellClass: 't-c',

    },
    {
      field: 'TaskEndtime',
      headerName: '任务结束时间',
      editable: false,
      width: 120,
      cellClass: 't-c',

    },
    {
      field: 'AssignUserName',
      headerName: '任务执行人',
      editable: false,
      width: 100,
      cellClass: 't-c',

    },
    {
      field: 'CreateUserName',
      headerName: '任务创建人',
      width: 90,
      cellClass: 't-c'
    },
    {
      field: 'IsCompleted',
      headerName: '完成状态',
      width: 90,
      cellClass: 't-c',
      valueFormatter(params) {
        const value = params.value
        return value==1 ? '是' : '否'
      }
    },
    {
      field: 'CompletedTime',
      headerName: '完成时间',
      width: 90,
      cellClass: 't-c'
    },
    {
      field: 'operation',
      headerName: '操作',
      editable: false,
      width: 100,
      cellClass: 't-c',
      cellRendererFramework: 'GridCellButtonRender',
    }
  ]

  export {
    columns

  }
