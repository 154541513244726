import request from '@/utils/request'


//客户盘点
export function GetMyTask(data) {
  return request({
    url: 'MyTasks/GetMyTask',
    method: 'post',
    data
  })
}

//客户盘点
export function GetCountInfo(data) {
		return request({
				url: 'MyTasks/GetCountInfo',
				method: 'post',
				data
		})
}

// 其他任务 检查完成
export function pullTask(data) {
	return request({
		url: 'MyTasks/HandlerOtherTask',
		method: 'post',
		data
	})
}
